import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import logo from '../../assets/footerlogo.png';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LinkTabNavHorizontal,
  IconEmailSent,
  InlineTextButton,
  SocialLoginButton,
  IconClose,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
} from '../../components';
import { ConfirmSignupForm, LoginForm, SignupForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { GiCheckMark } from 'react-icons/gi';
import css from './AuthenticationPage.module.css';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';
import { withViewport } from '../../util/contextHelpers';
import LandingPage from '../LandingPage/LandingPage';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      userType: '',
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      authModal: true,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      confirmError,
      submitSingupWithIdp,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      history,
      viewport,
    } = this.props;
    const isConfirm = tab === 'confirm';
    const isLogin = tab === 'login';
    const isProvider = tab === 'signup-provider';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;
    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;
    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      if (
        from === '/createlistingpage' &&
        currentUser?.attributes?.profile?.publicData.isProvider !== true
      ) {
        return <NamedRedirect name="LandingPage" />;
      } else {
        return <Redirect to={from} />;
      }
    } else if (isAuthenticated && currentUserLoaded) {
      if (currentUser?.attributes?.profile?.publicData.isProvider) {
        if (user?.attributes?.profile?.publicData?.showWelcomeMsg !== false) {
          return <NamedRedirect name="ProfileSettingsBasePage" />;
        } else {
          return <NamedRedirect name="ManageListingsPage" params={{ tab: 'activeListings' }} />;
        }
      } else {
        return <NamedRedirect name="LandingPage" />;
      }
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = { state: from ? { from } : null };

    const tabs = [
      {
        text: <h1 className={css.tab}>Sign Up</h1>,
        selected: !isLogin,
        onClick: () =>
          history.replace(createResourceLocatorString('SignupPage', routeConfiguration()), {
            from: locationFrom,
          }),
        linkProps: {
          name: 'SignupPage',
          to: locationFrom,
        },
      },
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h1>
        ),
        selected: isLogin,
        onClick: () =>
          history.replace(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), {
            from: locationFrom,
          }),
        linkProps: {
          name: 'LoginPage',
          to: locationFrom,
        },
      },
    ];
    const handleSubmitSignup = values => {
      const { fname, lname, isProvider, ...rest } = values;
      const signupFromMaybe = from ? { signupFrom: from } : {};
      const params = {
        firstName: fname.trim(),
        lastName: lname.trim(),
        isProvider: tab === 'signup-provider' ? true : false,
        ...signupFromMaybe,
        ...rest,
      };
      submitSignup(params);
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      submitSingupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };
    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
        </h1>

        <p className={css.confirmInfoText}>
          <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );
    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>

        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    ) : null;
    // Tabs for SignupForm and LoginForm
    const authenticationForms = (
      <div className={css.content}>
        <div className={css.logswitch}>
          <p>
            Sign Up today <span>for free</span>
          </p>
          <LinkTabNavHorizontal
            className={css.tabs}
            tabs={tabs}
            tabRootClassName={css.tabrc}
            selectedClassName={css.seltab}
          />
        </div>
        <div className={css.mobileTabs}>
          <button
            className={classNames(css.tabrc, css.tabMob, {
              [`${css.settabmob} ${css.seltab}`]: !isLogin,
            })}
            type="button"
            onClick={() =>
              history.replace(createResourceLocatorString('SignupPage', routeConfiguration()), {
                from: locationFrom,
              })
            }
          >
            Sign up
          </button>{' '}
          or
          <button
            className={classNames(css.tabrc, css.tabMob, {
              [`${css.settabmob} ${css.seltab}`]: isLogin,
            })}
            type="button"
            onClick={() =>
              history.replace(
                createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                {
                  from: locationFrom,
                }
              )
            }
          >
            Login
          </button>
        </div>
        <p className={classNames(css.fstbkp, { [css.hide_fstbkp]: isLogin })}>
          Bookings are 80% faster.
        </p>
        {locationFrom?.includes('/verify-email') && (
          <div className={css.verifiedCont}>
            <h1>Congratulations</h1>
            <p>1 step login</p>
            <span>Experience the thrill of faster booking on Activity Time</span>
          </div>
        )}
        {loginOrSignupError}
        {!isLogin && (
          <div className={css.dv3}>
            <p>How are you using Activity Time</p>
            <div>
              <div>
                <input
                  type="radio"
                  name="usertype"
                  id="parentSignup"
                  onChange={() => {
                    this.setState({ userType: 'parent' });
                    history.replace(
                      createResourceLocatorString('SignupPage', routeConfiguration()),
                      { from: locationFrom }
                    );
                  }}
                />
                <label htmlFor="parentSignup">
                  <span>{tab === 'signup' ? <GiCheckMark /> : null}</span>
                  <span>Parent / Teacher</span>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="usertype"
                  id="providerSignup"
                  onChange={() => {
                    this.setState({ userType: 'provider' });
                    history.replace(
                      createResourceLocatorString('SignupProviderPage', routeConfiguration()),
                      { from: locationFrom }
                    );
                  }}
                />
                <label htmlFor="providerSignup">
                  <span>{tab === 'signup-provider' ? <GiCheckMark /> : null}</span>
                  <span>Kids Activity Provider</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {isLogin ? (
          <LoginForm
            className={css.loginForm}
            history={history}
            onSubmit={submitLogin}
            inProgress={authInProgress}
            locationFrom={locationFrom}
          />
        ) : (
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
            tab={tab}
            history={history}
            locationFrom={locationFrom}
          />
        )}

        {socialLoginButtonsMaybe}
      </div>
    );

    const formContent = isConfirm ? confirmForm : authenticationForms;

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        {/* <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
          <span className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink> */}
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        {/* <LayoutSingleColumn> */}
        {/* <LayoutWrapperTopbar>
            <TopbarContainer className={topbarClasses} />
          </LayoutWrapperTopbar> */}
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            {/* {showEmailVerification ? emailVerificationContent : formContent} */}
            <LandingPage scrollingDisabled={true} />
          </div>
          <Modal
            id="AuthenticationPage.tos"
            // isOpen={true}
            isOpen={this.state.tosModalOpen}
            onClose={() => this.setState({ tosModalOpen: false })}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
            isFullScreen={true}
          >
            <div className={css.termsWrapper}>
              <h2 className={css.termsHeading}>
                <FormattedMessage id="AuthenticationPage.termsHeading" />
              </h2>
              <TermsOfService />
            </div>
          </Modal>
          <Modal
            isOpen={this.state.authModal}
            onClose={() =>
              this.setState({ authModal: true }, () => {
                if (currentUser?.id?.uuid) {
                  const isProvider = currentUser?.attributes?.profile?.publicData.isProvider;
                  const route =
                    isProvider && showEmailVerification ? 'ProfileSettingsBasePage' : 'LandingPage';
                  history.push(createResourceLocatorString(route, routeConfiguration(), {}, {}));
                } else if (history.length <= 2) {
                  history.push(
                    createResourceLocatorString('LandingPage', routeConfiguration(), {}, {})
                  );
                } else {
                  history.goBack();
                }
              })
            }
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
            id="mainModal"
            isFullScreen={true}
            className={css.authModal}
          >
            {!showEmailVerification && (
              <div className={css.dvone}>
                <p>
                  To use our <span>feature</span> tools we kindly ask you to <span>Sign Up</span> or{' '}
                  <span>Log In</span> first.
                </p>
                <span>Signing up takes 20sec or less</span>
              </div>
            )}
            {showEmailVerification ? emailVerificationContent : formContent}
          </Modal>
        </LayoutWrapperMain>
        {/* <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn> */}
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm', 'signup-provider']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(AuthenticationPageComponent);

export default AuthenticationPage;
